var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("task-detail-page", {
    staticClass: "views_shopping_mail_pages_task_detail bbox mt-10",
    attrs: {
      task_id: Number(_vm.$route.params.task_id),
      payment: Boolean(String(_vm.$route.params.payment) !== "0"),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }